export const socialMediaLinks = [
    {
        icon: `<i class='bx bxl-facebook' ></i>`,
        title: 'Facebook',
        link: 'https://www.facebook.com/UmrenEU/',
    },
    {
        icon: `<i class='bx bxl-youtube' ></i>`,
        title: 'Youtube',
        link: 'https://www.youtube.com/UmrenTV',
    },
    {
        icon: `<i class='bx bxl-linkedin' ></i>`,
        title: 'LinkedIn',
        link: 'https://www.linkedin.com/in/zdravevski/',
    },
    {
        icon: `<i class='bx bxl-github' ></i>`,
        title: 'GitHub',
        link: 'https://github.com/UmrenTV',
    },
    {
        icon: `<i class='bx bxl-twitter' ></i>`,
        title: 'Twitter',
        link: 'https://twitter.com/UmrenTV',
    },
];

export const email = 'jane@zdravevski.pro';
